*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

*::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
    background-color: #777777;
    border: 2px solid #777777;
    border-radius: 5px;
}