// Custom.scss
// Option B: Include parts of CoreUI
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import './../../node_modules/@coreui/coreui-pro/scss/functions';
// 2. Include any default variable overrides here
$font-size-base: 0.83rem;
$input-btn-padding-x:.55rem;
$input-btn-padding-y:.35rem;
$input-padding-y: .4rem;
$form-label-margin-bottom: .2rem;
$form-select-padding-y:0.25rem;
$form-select-padding-x:0.5rem;

// 3. Include remainder of required CoreUI stylesheets
@import "./../../node_modules/@coreui/coreui-pro/scss/variables";
// 4. Include any default map overrides here
// 5. Include remainder of required parts
@import "./../../node_modules/@coreui/coreui-pro/scss/maps";
@import "./../../node_modules/@coreui/coreui-pro/scss/mixins";
@import "./../../node_modules/@coreui/coreui-pro/scss/utilities";
// 6. Optionally include any other parts as needed
// Layout & components
@import "./../../node_modules/@coreui/coreui-pro/scss/root";
@import "./../../node_modules/@coreui/coreui-pro/scss/reboot";
@import "./../../node_modules/@coreui/coreui-pro/scss/type";
@import "./../../node_modules/@coreui/coreui-pro/scss/images";
@import "./../../node_modules/@coreui/coreui-pro/scss/containers";
@import "./../../node_modules/@coreui/coreui-pro/scss/grid";
@import "./../../node_modules/@coreui/coreui-pro/scss/tables";
@import "./../../node_modules/@coreui/coreui-pro/scss/forms";
@import "./../../node_modules/@coreui/coreui-pro/scss/buttons";
@import "./../../node_modules/@coreui/coreui-pro/scss/loading-button";
@import "./../../node_modules/@coreui/coreui-pro/scss/transitions";
@import "./../../node_modules/@coreui/coreui-pro/scss/dropdown";
@import "./../../node_modules/@coreui/coreui-pro/scss/button-group";
@import "./../../node_modules/@coreui/coreui-pro/scss/nav";
@import "./../../node_modules/@coreui/coreui-pro/scss/navbar";
@import "./../../node_modules/@coreui/coreui-pro/scss/card";
@import "./../../node_modules/@coreui/coreui-pro/scss/accordion";
@import "./../../node_modules/@coreui/coreui-pro/scss/breadcrumb";
@import "./../../node_modules/@coreui/coreui-pro/scss/pagination";
@import "./../../node_modules/@coreui/coreui-pro/scss/badge";
@import "./../../node_modules/@coreui/coreui-pro/scss/alert";
@import "./../../node_modules/@coreui/coreui-pro/scss/progress";
@import "./../../node_modules/@coreui/coreui-pro/scss/list-group";
@import "./../../node_modules/@coreui/coreui-pro/scss/close";
@import "./../../node_modules/@coreui/coreui-pro/scss/toasts";
@import "./../../node_modules/@coreui/coreui-pro/scss/modal";
@import "./../../node_modules/@coreui/coreui-pro/scss/tooltip";
@import "./../../node_modules/@coreui/coreui-pro/scss/popover";
@import "./../../node_modules/@coreui/coreui-pro/scss/carousel";
@import "./../../node_modules/@coreui/coreui-pro/scss/spinners";
@import "./../../node_modules/@coreui/coreui-pro/scss/offcanvas";
@import "./../../node_modules/@coreui/coreui-pro/scss/placeholders";
@import "./../../node_modules/@coreui/coreui-pro/scss/avatar";
@import "./../../node_modules/@coreui/coreui-pro/scss/calendar";
@import "./../../node_modules/@coreui/coreui-pro/scss/callout";
@import "./../../node_modules/@coreui/coreui-pro/scss/picker";
@import "./../../node_modules/@coreui/coreui-pro/scss/date-picker";
@import "./../../node_modules/@coreui/coreui-pro/scss/time-picker";
@import "./../../node_modules/@coreui/coreui-pro/scss/footer";
@import "./../../node_modules/@coreui/coreui-pro/scss/header";
@import "./../../node_modules/@coreui/coreui-pro/scss/icon";
@import "./../../node_modules/@coreui/coreui-pro/scss/sidebar";
@import "./../../node_modules/@coreui/coreui-pro/scss/subheader";
// Helpers
@import "./../../node_modules/@coreui/coreui-pro/scss/helpers";
// Utilities
@import "./../../node_modules/@coreui/coreui-pro/scss/utilities/api";
@import "./../../node_modules/@coreui/coreui-pro/scss/utilities/bg-gradients";
// scss-docs-end import-stack