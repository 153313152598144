#HrManageWorkTimes,
#HrManageTemplate {
    height: 100%;
}

.hr-presentation {
    &--wrapper {
        width: 100%;
        display: flex;
    }
    &--timetable {
        flex: 1;
        flex-grow: 1;
        flex-shrink: 1;
        overflow-x: auto;
        overflow-y: hidden;
        display:flex;
    }
    &--form {
        flex-basis: 0px;
        transition: flex-basis 1s, width 1s;
    }
}

.hr-form {
    //padding-left: 20px;
    &--title {
        height: 30px;
        border-bottom: solid 1px $secondary;
        vertical-align: middle;
        margin-bottom: 5px;
    }
    &--close {
        float: right;
        width: 30px;
        text-align: center;
        vertical-align: middle;
    }
    .btn-outline-secondary {
        color: #7a7c81;
    }
}

.timetable {
    text-align: left;
    width: max-content;
    overflow-x:scroll;
}

.timetable--verticalscroll {
    display: flex;
    flex-direction: row;
}

.timetable--verticalscroll>div {
    /*flex: 1;*/
}

.timetable__legend {
    flex: 0 0 auto;
}

.timetable__day {
    display: flex;
    flex-direction: column;
    border-right: solid 3px black;
    &.weekday0,
    &.weekday6 {
        .timetable__day__name {
            background-color: #eee;
        }
    }
}

.timetable__day:first-child {
    border-left: solid 2px black;
}

.timetable__day__name {
    text-align: left;
    white-space: nowrap;
}

.timetable__day__content {
    display: flex;
}

.timetable__day__note {
    font-size: 0.7rem;
    .form-control {
        font-size: 0.7rem;
        padding: 4px;
    }
    & span {
        padding: 2px;
        display: inline-block;
    }
}

.timetable__section {
    border-right: solid 3px grey;
    float: left;
}

.timetable__section:last-child {
    border: none;
}

.timetable__section__name {
    text-align: left;
    overflow: hidden;
    cursor: pointer;
    i.fa {
        font-size: 0.7em;
    }
}

.timetable__section__content {}

.timetable__timespan {
    border-left: solid 1px grey;
    float: left;
}

.timetable__timespan:first-child {
    border: none;
}

.timetableReport {
    &__profileWorkTimes {
        font-size: 0.9em;
    }
    &__settings {
        .form-check {
            margin-right:1rem;
        }
    }
}

// media queries
@include media-breakpoint-down(lg) {
    .worktimegrid {
        .modal-position {
            display: none;
        }
        &.open {
            .gridcontainer {
                display: none;
            }
            .modal-position {
                display: block;
                width: 100%;
            }
        }
    }
}